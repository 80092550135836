
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cl_cl_mlt_fec_app_ui-primary: mat.define-palette(mat.$indigo-palette);
$cl_cl_mlt_fec_app_ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cl_cl_mlt_fec_app_ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cl_cl_mlt_fec_app_ui-theme: mat.define-light-theme((
  color: (
    primary: $cl_cl_mlt_fec_app_ui-primary,
    accent: $cl_cl_mlt_fec_app_ui-accent,
    warn: $cl_cl_mlt_fec_app_ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cl_cl_mlt_fec_app_ui-theme);

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
//@import '@angular/material/prebuilt-themes/indigo-pink.css';
//@import '~swiper/swiper-bundle.min.css';
@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
//@import "app/theme/styles/admin";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
