@import "variables";

/******* Base styles *******/
html{
  height: 100%;
}
body{
  height: 100%;
  margin:0; padding: 0;
  font-family: $font-family;
  font-size: 14px;
  line-height: 1.5;
}
*{
  margin: 0;
  padding: 0;
}
a{
  outline: none;
}
.h-100 {
  height: 100% !important;
}
.w-100{
  width: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100{
  max-width: 100%;
}
.fw-300{
  font-weight: 300;
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.lh{
  line-height: 27px;
}
.d-block {
  display: block !important;
}
.theme-container{
  max-width: $theme-max-width;
  margin: 0 auto;
  width: 100%;
}
.light-block.mat-mdc-card{
  background: rgba(0,0,0,0.03) !important;
  box-shadow: none !important;
}
.transition{
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}
.text-truncate{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.divider{
  border-top: 1px solid rgba(204, 204, 204, 0.6);
}

.mat-mdc-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs{
  font-size: 14px !important;
  line-height: 14px !important;
  height: 14px !important;
  width: 14px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-sm{
  font-size: 18px !important;
  line-height: 18px !important;
  height: 18px !important;
  width: 18px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-md,
.mat-icon.mat-icon-md,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-sm{
  font-size: 24px !important;
  line-height: 24px !important;
  height: 24px !important;
  width: 24px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg{
  font-size: 36px !important;
  line-height: 36px !important;
  height: 36px !important;
  width: 36px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg{
  font-size: 48px !important;
  line-height: 48px !important;
  height: 48px !important;
  width: 48px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}
.mat-mdc-button>.mat-icon.caret{
  width: 14px;
  margin: 0 !important;
}
.mat-step-text-label{
  text-transform: uppercase;
}
.mat-mdc-row, .mat-mdc-header-row{
  border-bottom-color: rgba(0,0,0,.12);
}

.pages-container .mat-drawer-content {
  overflow: hidden;
}

.filter-sidenav .mat-drawer-inner-container{
  padding: 2px;
  box-sizing: border-box;
  .mat-expansion-panel{
    border-radius: 0 !important;
  }
}

.app-dropdown.mat-mdc-menu-panel{
  min-width: 60px !important;
}
.app-dropdown .mat-mdc-menu-item{
  min-height: 36px;
  line-height: 36px;
  font-size: 14px;
  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
  }
}
.account-icon{
  margin-top: -2px;
  margin-right: 2px;
}
.app-dropdown.account{
  .mat-mdc-menu-content{
    padding: 0;
    .user-info{
      padding: 16px;
      img{
        margin-right: 16px;
        border-radius: 3px;
      }
      .mat-icon{
        margin-top:-2px;
        color: #fbc02d;
      }
    }
  }
}
.categories-dropdown.mat-mdc-menu-panel{
  max-height: 350px;
}
.categories-dropdown .mat-mdc-menu-item{
  min-height: 24px;
  line-height: 24px;
  font-size: 14px;
}
.sub-category button{
  padding-left: 30px;
}
.sub-category .sub-category button{
  padding-left: 50px;
}

.d-block{
  display: block;
}

.info-bar{
  margin-top: 30px;
  .mat-mdc-card{
    height: 100px;
    .content{
      margin-left: 10px;
      p{
        font-weight: 500;
      }
      span{
        font-size: 13px;
      }
    }
  }
}


.social-icon{
  width: 48px;
  height: 48px;
  cursor: pointer;
  fill: #8f8f8f;
  outline: none;
}

@media (min-width: 960px) {
  .app{
    &.main-toolbar-fixed{
      #menu-toolbar{
        position: fixed;
        top: 0px;
        width: 100%;
        z-index: 9;
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),
        0 2px 2px 0 rgba(0,0,0,.14),
        0 1px 5px 0 rgba(0,0,0,.12);
      }
      .main{
        padding-top: 64px;
      }
    }
  }
}

/******* Toolbar *******/
.top-toolbar.mat-toolbar-row{
  height: 32px;
  font-size: 13px;
  .sidenav-toggle{
    height: 31px;
    line-height: 31px;
    min-width: 24px;
    padding: 0;
    border-radius: 0;

  }
  .top-menu{
    margin-right: -16px;
    text-transform: uppercase;
    a{
      text-decoration: none;
      font-weight: 400;
      font-size: 13px;
      line-height: 31px;
      padding: 0 10px;
      min-width: 70px;
      border-radius: 0;
      height: 30px;
      text-transform: uppercase;
      .mdc-button__label {
        display: flex;
      }
    }
    a.mobile-menu-icon{
      min-width: 36px;
    }
    .mat-icon.more{
      height: 28px;
    }
  }
}
.flag-menu-title{
  vertical-align: top !important;
  margin-left: 3px;
}
.top-menu-dropdown .mat-mdc-menu-item img{
  vertical-align: middle;
  margin-right: 8px;
}
.cart-dropdown.mat-mdc-menu-panel{
  padding: 6px 14px;
  font-size: 13px;
  .list-item {
    padding: 8px 0;
    h3 {
      font-size: 14px;
      font-weight: 500;
    }
    p {
      font-size: 13px;
      font-weight: 400;
    }
    img{
      width: 70px;
      margin-left: 10px;
    }
  }
  .cart-dropdown-footer{
    font-size: 14px;
  }
}

.shopping-cart-btn {
  color: #fff !important;
  .mat-badge-content {
    right: 6px !important;
  }
}


.app-menu-panel.mat-mdc-menu-panel{
  min-width: 60px;
  .mat-mdc-menu-content:not(:empty){
    padding: 0;
  }
  .mat-mdc-menu-item{
    display: flex;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    .mat-badge-small.mat-badge-above .mat-badge-content{
      top: 8px;
    }
  }
  &.lang{
    min-width: 48px;
    .mat-mdc-menu-item{
      line-height: 36px;
      min-height: 36px;
      padding: 0;
      .mdc-list-item__primary-text {
        width: 100%;
      }
      img{
        display: block;
        margin: 0 auto;
      }
    }
  }
}

.logo-toolbar.mat-toolbar-row{
  height: 104px;
}
.logo{
  font-size: 28px;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
}
.search-form{
  border-radius: 22px;
  overflow: hidden;
  .categories{
    height: 40px;
    width: 180px;
    border-radius: 0;
    white-space: nowrap;
  }
  input[type=text]{
    border: 0;
    outline: none;
    padding: 0 34px 0 12px;
    border-left: 1px solid #ccc;
  }
  .search-btn{
    margin-left: -20px;
    background: #fff !important;
    box-shadow: none !important;
  }
}
.search-dropdown.mat-mdc-menu-panel{
  border-radius: 26px;
  max-width: 100% !important;
  min-height: auto;
  .mat-mdc-menu-content{
    padding: 0;
  }
}
.top-cart{
  span{
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
  }
}


/******* Top navbar (menu) *******/
.top-navbar.mat-toolbar {
  height: 48px;
  padding: 0;
  a{
    min-width: 70px;
    border-radius: 0;
    text-transform: uppercase;
    padding: 6px 16px;
    font-weight: 500;
    height: 48px;
  }
}
.cdk-overlay-pane.mega-menu-pane{
  left: 0 !important;
  width: 100%;
  .mat-mdc-menu-panel{
    width: 100%;
    max-width: none;
    border-radius: 0;
    .mat-mdc-menu-content{
      padding: 0;
    }
  }
  .mega-menu-widget{
    position: relative;
    button.mdc-button {
      position: absolute;
      top: 10px;
      left: 10px;
      font-weight: 400;
    }
    .mat-mdc-chip-listbox {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }
}


/******* Carousel *******/
button.swipe-arrow{
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}
.swiper-pagination.white .swiper-pagination-bullet{
  background: #fff;
}
.swiper-pagination-bullet-active{
  width: 12px;
  height: 12px;
  vertical-align: -2px;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after{
  content: none;
}

/******* Product *******/
.product-item {
  position: relative;
  padding: 16px;
  .mat-mdc-chip-listbox {
    position: absolute;
    top: 10px;
    right: 10px;
    &.list-view{
      left: 10px;
    }
  }
  .image-link{
    display: block;
  }
  img{
    max-width: 100%;
    margin-bottom: 12px;
  }
  .category{
    margin-bottom: 4px;
  }
  .title{
    display: block;
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
  }
  .prices{
    margin: 12px 0;
    .old-price{
      text-decoration: line-through;
      height: 15px;
    }
    .new-price{
      font-size: 16px;
      font-weight: 500;
    }
  }
  .icons{
    margin-top: 18px;
  }
}
.old-price{
  text-decoration: line-through;
}

/******* Back to top *******/
.back-to-top{
  position: fixed;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 999999;
  right: 20px;
  bottom: 20px;
  opacity: .5;
  color: #fff;
  background-color: rgba(0,0,0,.75);
  border-radius: 4px;
  &:hover{
    opacity: 0.9;
  }
}



/******* Responsive *******/
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
  .top-toolbar.mat-toolbar-row{
    padding: 0;
    .top-menu{
      margin-right: 0;
      a{
        min-width: 56px;
      }
    }
    .sidenav-toggle{
      min-width: 52px;
    }
  }
  .search-form input[type=text]{
    width: 160px;
  }
  .search-form .categories{
    width: 140px;
  }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) {
  .top-toolbar.mat-toolbar-row{
    padding: 0;
    .top-menu{
      margin-right: 0;
    }
    .sidenav-toggle{
      min-width: 52px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
  .top-toolbar.mat-toolbar-row{
    padding: 0;
    .top-menu{
      margin-right: 0;
    }
    .sidenav-toggle{
      min-width: 52px;
    }
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {  }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {  }

@media (min-width: 1380px) {
  .theme-container{
    max-width: 1400px;
  }
}

.sidenav .mat-drawer-inner-container {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover{
    background: #000;
  }
  &:active{
    background: #888888;
  }
}
::-webkit-scrollbar-track {
  background: #0082c0;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover{
    background: #0082c0;
  }
  &:active{
    background: #333333;
  }
}
::-webkit-scrollbar-corner {
  background: transparent;
}


//----------ng-scrollbar---------------
ng-scrollbar {
  --scrollbar-size: 6px !important;
  --scrollbar-thumb-color: rgba(204,204,204,0.8) !important;
  --scrollbar-thumb-hover-color: #ccc !important;
  --scrollbar-border-radius: 4px !important;
  --scrollbar-hover-size: 8px !important;
  --scrollbar-padding: 2px !important;
}


.menu-sidenav {
  background-color: #146d9f !important;
  color: #fff !important;
  width: 270px !important;
  .mat-tree {
    background-color: transparent !important;
    .mat-icon{
      color: white !important;
    }

    .node-description{
      color: white !important;
    }

    .child {
      background-color: #146d9f !important;
      color: #fff !important;
      width: 100% !important;
      text-align: left !important;
      padding: 0px 0 0px 8px !important;
    }
    .child:hover {
      background-color: #0082c0 !important;
      color: white !important;
      transition: 0.3s !important;
    }
  }
}

.menu-sidenav-content > *:not(.exclude) {
  width: 90% !important;
}

/*Reduces mat form field´s height*/

mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix {
  padding: 0.4em 0px
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper {
  top: -1.4em;
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper {
  top: -1.4em;
}


.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(.75);
  width: 133.33333%;
}

/*Changes base color of inkbar*/
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0082c0;
}

/*Changes base color of slide toggle*/

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(97 161 225 / 70%);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0082c0;
}

.container {
  padding: 20px;
}

.border {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
}

.mat-paginator {
  background-color: transparent !important;
}

//////// Changes base color of checkboxes ////////

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1b81be !important;
}

.mat-dialog-content {
  padding: 1em !important;
}

/*Boton a la par de un campo*/
.e-bill {
  margin-top: -19px !important;
  border-radius: 0 5px 5px 0 !important;
  background-color: #0082c0 !important;
  color: white !important;
  cursor: pointer !important;
  height: 36px;
}

/*Elimina el borde derecho de algunos inputs y botones para hacer un input group*/

.input-group .mat-form-field-outline-end {
  border-radius: 0 0px 0px 0 !important;
  border-right: none !important;
}

.input-group-start .mat-form-field-outline-start {
  border-radius: 0 0px 0px 0 !important;
}

.row-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 650px) {
  .row-group{
    display: flex;
    flex-direction: column !important;
  }
  .e-bill {
    border-radius: 5px !important;
    width: 100%;
  }
  .input-group .mat-form-field-outline-end {
    border-right: 1px solid !important;
    border-radius: 4px !important;
  }
}
