@use '@angular/material' as mat;
@include mat.core();

@import 'theme-reset';
.app{
  &.green{
    @import "skins/green";
    @include mat.all-component-colors($green-theme);
    @include theme-reset($green-theme);
  }
  &.blue{
    @import "skins/blue";
    @include mat.all-component-colors($blue-theme);
    @include theme-reset($blue-theme);
  }
  &.red{
    @import "skins/red";
    @include mat.all-component-colors($red-theme);
    @include theme-reset($red-theme);
  }
  &.pink{
    @import "skins/pink";
    @include mat.all-component-colors($pink-theme);
    @include theme-reset($pink-theme);
  }
  &.purple{
    @import "skins/purple";
    @include mat.all-component-colors($purple-theme);
    @include theme-reset($purple-theme);
  }
  &.grey{
    @import "skins/grey";
    @include mat.all-component-colors($grey-theme);
    @include theme-reset($grey-theme);
  }

  &.clavis{
    @import "skins/clavis";
    @include mat.all-component-colors($ClvsFEAdminUI-theme);
    @include theme-reset($ClvsFEAdminUI-theme);
  }
}
